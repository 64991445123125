import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Soja-Tofu-Workshop",
  "preis": "35,00-60,00"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Unser Workshop gibt die Möglichkeit in der Gruppe einmal bei der Tofuproduktion mit dabei zu sein. Gemensam hacken wir die Bohnen, Kochen und Rühren, Trennen Milch und Okara.
Und Milch und Molke, hier bekommen alle Teilnehmer*innen die Möglichkeit ihren eigenen Bruch zu erstellen. Gemeinsam essen wir dann unsere Tofusuppe, eine wunderbare Delikatesse.
Abschließend kommt der Tofu in die Presse und wir widmen uns noch einmal dem Okara, um am Ende etwas Tofu und etwas Okara mit Rezepten und Ideen nach Hause zu nehmen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      